import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import favicon from "./images/favicon.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function NavBar() {
  const location = useLocation();
  console.log(location);

  return (
    <>
      <Navbar expand="lg" className="navbaro">
        <Container>
          <Link to="/Heroo">
            {" "}
            <img src={favicon} alt="" />{" "}
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className="   col-lg-12 col-md-2 col-sm-1 navs">
              <Link
                className={` item ${
                  location.pathname === "/Heroo" ? "active" : ""
                } `}
                to="/Heroo"
              >
                Home
              </Link>
              <Link
                className={` item ${
                  location.pathname === "/About" ? "active" : ""
                } `}
                to="/About"
              >
                About
              </Link>
              <Link
                className={` item ${
                  location.pathname === "/Service" ? "active" : ""
                } `}
                to="/Service"
              >
                Service
              </Link>
              <Link
                className={` item ${
                  location.pathname === "/Portfolio" ? "active" : ""
                } `}
                to="/Portfolio"
              >
                Portfolio
              </Link>
              <Link
                className={` item me-3 ${
                  location.pathname === "/Testi" ? "active" : ""
                } `}
                
                to="/Testi"
              >
                Testimonilas
              </Link>
              <Link
                className={` item text-center ${
                  location.pathname === "/Blog" ? "active" : ""
                } `}
               
                to="/Blog"
              >
                Blog
              </Link>
              <Link
                className={` item ${
                  location.pathname === "/Contact" ? "active" : ""
                } `}
             
                to="/Contact"
              >
                Contact
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
