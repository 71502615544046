import React from "react";
// import React, { useEffect } from "react"
import { about } from "../dummydata";
import man from "../components/images/man.png";
export default function About() {

  return (
    <>
      <section className="about">
        <div className="container ">
          {about.map((val, i) => (
            <>
              <div className="left">
                <img src={man} alt="" />
              </div>
              <div className="right col-lg-7 ms-5">
                <h1>About me</h1>
                <p>{val.desc}</p>
                <p>{val.desc1}</p>
                <a href="https://drive.google.com/drive/folders/10K1Z-DcUx9b5TREWJ2wKSdl-CQsumkGT?usp=sharing " target="_blank" rel="noopener noreferrer">
           <button  className="primaryBtn" data-aos="fade-up-right">
              Download CV
            </button>
           </a>
  
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  );
}
