import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import port1 from "../images/port/Screenshot (90).png";
import port2 from "../images/port/Screenshot (91).png";
import port3 from "../images/port/Screenshot (92).png";
import port4 from "../images/port/Screenshot (93).png";
import port5 from "../images/port/Screenshot (94).png";
import port6 from "../images/port/Screenshot (95).png";
import port7 from '../imgss/IMG-20240819-WA0008.jpg'
import port8 from '../imgss/IMG-20240819-WA0010.jpg'
import port9 from '../imgss/IMG-20240819-WA0011.jpg'
import port10 from '../imgss/IMG-20240819-WA0012.jpg'
import port11 from '../imgss/IMG-20240819-WA0013.jpg'
import port12 from '../imgss/IMG-20240819-WA0014.jpg'
import port13 from '../imgss/IMG-20240819-WA0015.jpg'
import port14 from '../imgss/IMG-20240819-WA0015.jpg'
import port15 from '../imgss/IMG-20240819-WA0016.jpg'
import port16 from '../imgss/IMG-20240819-WA0017.jpg'
import port17 from '../imgss/IMG-20240819-WA0018.jpg'
import port19 from '../imgss/smartmockups_m000gbz6.jpg'
import port20 from '../imgss/smartmockups_m000ih4l.jpg'

import React, { useState } from "react";
const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const buttons = [
    { id: 1, cat: "All" },
    { id: 2, cat: "Design" },
    { id: 3, cat: "Marketing" },
    { id: 4, cat: "Development" },
  ];

  const portfolioItems = [
    { id: 1, cover: port1, name: "Read More", category: "All", title: "Pdf Tool", link: "https://www.ilovepdf.com/" },
    { id: 2, cover: port2, name: "Read More", category: "All", title: "Seo Tools", link: "https://smallseotoolsite.com/" },
    { id: 3, cover: port3, name: "Read More", category: "All", title: "Code Hippo", link: "https://codehippo.net/" },
    { id: 4, cover: port4, name: "Read More", category: "All",  title: "Codeinca Agency", link: "https://codeinca.com/" },
    { id: 5, cover: port5, name: "Read More", category: "All", title: "Text Tools", link: "https://allinonetxttools.blogspot.com/" },
    { id: 6, cover: port6, name: "Read More", category: "All", title: "Mega Web Tools", link: "https://megawebtools.codeinca.com/" },
    { id: 7, cover: port7, name: "Read More", category:   "All", title: "Marketing", link: "https://codeinca.com/" },
    { id: 8, cover: port8, name: "Read More", category:   "All", title: "Marketing", link: "https://codeinca.com/" },
    { id: 9, cover: port9, name: "Read More", category:   "All", title: "Marketing", link: "https://codeinca.com/" },
    { id: 18, cover: port19, name: "Read More", category: "All", title: "Designs", link: "https://www.codeinca.com/" },
    { id: 19, cover: port20, name: "Read More", category: "All", title: "Designs", link: "https://www.codeinca.com/" },
    { id: 11, cover: port11, name: "Read More", category: "All", title: "Marketing", link: "https://codeinca.com/" },
    { id: 7, cover: port7, name: "Read More", category:   "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 8, cover: port8, name: "Read More", category:   "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 9, cover: port9, name: "Read More", category:   "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 10, cover: port10, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 11, cover: port11, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 12, cover: port12, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 13, cover: port13, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 14, cover: port14, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 15, cover: port15, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    // { id: 16, cover: port16, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    // { id: 17, cover: port17, name: "Read More", category: "Marketing", title: "Marketing", link: "https://codeinca.com/" },
    { id: 18, cover: port19, name: "Read More", category: "Design", title: "Designs", link: "https://www.codeinca.com/" },
    { id: 19, cover: port20, name: "Read More", category: "Design", title: "Designs", link: "https://www.codeinca.com/" },
    { id: 18, cover: port19, name: "Read More", category: "Development", title: "Designs", link: "https://www.codeinca.com/" },
    { id: 19, cover: port20, name: "Read More", category: "Development", title: "Designs", link: "https://www.codeinca.com/" },
    { id: 1, cover: port1, name: "Read More", category: "Development", title: "Pdf Tool", link: "https://www.ilovepdf.com/" },
    { id: 2, cover: port2, name: "Read More", category: "Development", title: "Seo Tools", link: "https://smallseotoolsite.com/" },
    { id: 3, cover: port3, name: "Read More", category: "Development", title: "Code Hippo", link: "https://codehippo.net/" },
    { id: 4, cover: port4, name: "Read More", category: "Development",  title: "Codeinca Agency", link: "https://codeinca.com/" },
    { id: 5, cover: port5, name: "Read More", category: "Development", title: "Text Tools", link: "https://allinonetxttools.blogspot.com/" },
    { id: 6, cover: port6, name: "Read More", category: "Development", title: "Mega Web Tools", link: "https://megawebtools.codeinca.com/" },
  ];

  const filteredItems = portfolioItems.filter(item => 
    selectedCategory === "  " || item.category === selectedCategory
  );
  return (
    <>
       <section className="portfolio">
      <h1 className="mt-3">Portfolio</h1>
      <div className="catButton m-lg-5 mt-3 p-md-1">
        {buttons.map(button => (
          <button
            key={button.id}
            className={`primaryBtn ${selectedCategory === button.cat ? 'active' : ''}`}
            onClick={() => setSelectedCategory(button.cat)}
            data-aos="zoom-out-down"
          >
            {button.cat}
          </button>
        ))}
      </div>
      <div className="portfolio-grid">
        {filteredItems.map(item => (
          <div className="box-1 " key={item.id} data-aos="fade-up">
            <div className="img">
              <img src={item.cover} alt={item.title} />
            </div>
            <div className="overlay">
              <h3 style={{ color: "#fff" }}>{item.title}</h3>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <span>{item.name}</span>
              </a>
              <i style={{ color: "#fff" }}>
                <VisibilityOutlinedIcon />
              </i>
            </div>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default Portfolio;
